//Desktop banners
import banner01 from '../assets/banners/banner01.png'
import banner02 from '../assets/banners/banner02.png'
import banner03 from '../assets/banners/banner03.png';
import banner04 from '../assets/banners/banner04.png';
import banner05 from '../assets/banners/banner05.png';

//Mobile banners
import banner01_mobile from '../assets/bannersMobile/banner01.png';
import banner05_mobile from '../assets/bannersMobile/banner05.png';

export const desktop_banners = [banner01, banner02, banner03, banner04, banner05];
export const mobile_banners = [banner01_mobile, banner05_mobile];