import { useEffect, useState } from 'react';
import './NewsSlider.scss';
import NewsCard from '../NewsCard/NewsCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import "swiper/css/navigation";
import { news } from '../../data/news';

function NewsSlider() {
    /* This piece of code listens to window's size changes and
    adjusts the number of slides per view in to fit the screen */

    const [slidesCount, setSlidesCount] = useState(3);

    useEffect(() => {
        function updateSlidesCount(){
            const width = window.innerWidth;

            if(width >= 1485){
                setSlidesCount(3);
            } else if (width < 1485 && width >= 1000) {
                setSlidesCount(2);
            } else if (width < 1000) {
                setSlidesCount(1);
            }
        }

        updateSlidesCount();

        window.addEventListener('resize', updateSlidesCount);

        return () => {
            window.removeEventListener('resize', updateSlidesCount);
        };
    }, [])

    return (
        <div className="news-slider">
            <h2>Notícias</h2>

            <Swiper
                slidesPerView={slidesCount}
                spaceBetween={30}
                navigation={true}
                modules={[ Navigation ]}
                className="mySwiper"
                >

                {news.map(item =>
                    <SwiperSlide>
                        <NewsCard
                            image={item.image}
                            alt={item.alt}
                            imgRights={item.imgRights}
                            title={item.title}
                            content={item.content}
                            date={item.date}
                        />
                    </SwiperSlide>
                )}

            </Swiper>
        </div>
    )
}

export default NewsSlider;
