import { Link } from 'react-router-dom';
import './DesktopMenu.scss';

function DesktopMenu() {
  return (
    <nav className='desktop-menu'>
        <Link to='/'>INÍCIO</Link>
        <Link to='/contatos'>CONTATO</Link>
    </nav>
  )
}

export default DesktopMenu;