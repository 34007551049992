import './Rectangle.scss';

function Rectangle({ content }) {
    return (
        <div className='rectangle'>
            <h3>{ content }</h3>
        </div>
    )
}

export default Rectangle;