import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import "swiper/css/navigation";
import Header from '../../layout/Header/Header';
import Footer from '../../layout/Footer/Footer';
import Card from '../../components/Card/Card';
import { services } from '../../data/services';
import Rectangle from '../../components/Rectangle/Rectangle';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import comtrafo from '../../assets/clients/comtrafo.png';
import enertrafo from '../../assets/clients/Enertrafo.png';
import romagnole from '../../assets/clients/romagnole.png';
import alvarez from '../../assets/clients/AeM.png';
import weg from '../../assets/clients/WegLogo.png';
import ufpr from '../../assets/partners/UFPRLogo.png';
import chronion from '../../assets/partners/ChronionLogo.png';
import { moreAbout } from '../../data/moreAbout';
import { desktop_banners } from '../../data/banners';
import './LandingPage.scss';
import NewsSlider from '../../components/NewsSlider/NewsSlider';

function LandingPage() {
  const [showText, setShowText] = useState(false);
  const buttonText = showText === false ? 'Mostrar mais' : 'Mostrar menos';

  const clientsLogos = [
    weg,
    romagnole,
    comtrafo,
    enertrafo,
    alvarez
  ];

  const partnersLogos = [
    ufpr,
    chronion
  ];

  const qualities = [
    'Mais de 35 anos de experiência',
    'Profissionalismo',
    'Foco no cliente',
    'Confidencialidade'
  ]

  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 500,
      easing: 'ease-in-sine',
      once: true
    });
  }, []);

  return (
    <>
    <Header />

    <Swiper
      navigation={true}
      centeredSlides={true}
      modules={[Navigation, Autoplay]}
      autoplay={{delay:3200, disableOnInteraction: false,}}
      className="mySwiper"
    >
      {desktop_banners.map(banner =>
        <SwiperSlide><img src={banner} alt="" /></SwiperSlide>
      )}

    </Swiper>

    <NewsSlider />

    <h2 className='services-title'>Portifólio</h2>

    <div className='servicos'>
      {services.map(service =>
        <Card
          icon={service.icon}
          title={service.title}
          text={service.text}
        />
      )}
    </div>

    <div className='clients'>
        <h2>Clientes</h2>
        <div className='clients-row'>
          {clientsLogos.map(logo =>
            <img src={logo} alt='logo do cliente' className='client-logo'/>
          )}
        </div>
    </div>

    <div className="partners">
      <h2>Parceiros</h2>
      <div className='clients-row'>
          {partnersLogos.map(logo =>
            <img src={logo} alt='logo do parceiro' className='partner-logo'/>
          )}
        </div>
    </div>

    <div className='about'>
      <div className='about-column who' data-aos='fade-right' >
        <h2 className='who-title'>Quem somos</h2>
        <p>
        A OILFLEX é uma empresa de consultoria e elaboração de projetos de pesquisa e desenvolvimento, fundada em 2006, voltada para atender as necessidades dos setores produtivos, seja industrial, comercial e de geração, transmissão e distribuição de energia elétrica.
        Os profissionais que atuam em parceria com nossa empresa de consultoria e pesquisa são especialistas renomados, alguns com mais de 35 anos de experiência no setor elétrico nacional. Estes profissionais atuam e participam dos órgãos normativos nacionais e internacionais tais como ABNT, Comitê Brasileiro de Eletricidade - COBEI, CIGRE, entre outros.
        A área de atuação está direcionada em consultoria e suporte em projetos para a geração de energia, em sistemas elétricos de isolamento sólido e líquido de equipamentos elétricos (transformadores, reatores, religadores, disjuntores etc), mais especificamente óleo isolante (minerais e ésteres naturais), além de suporte para avaliação de contaminações ambientais relacionadas.
        Outro foco importante está na elaboração e na gestão de projetos de geração de energia de forma renovável, visando atender processos ambientais e de crédito de carbono, para empresas do setor elétrico.
        </p>
        <button onClick={() => setShowText(!showText)}> {buttonText} </button>
      </div>

      <div className='grid-holder' data-aos='fade-left' >
        <div className='about-column qualities'>
          {qualities.map(quality =>
            <Rectangle
              content={quality}
            />
          )}
        </div>
      </div>
    </div>

    {showText &&
      <div className='more-about' data-aos='fade-right' >
        <h2>Mais sobre a Oilfex</h2>
        {moreAbout}
      </div>
    }

    <div className='contact'>
      <h2>Vamos trabalhar juntos!</h2>
      <Link to='/contatos'><button>Entre em contato</button></Link>
    </div>

    <Footer />

    </>
  )
}

export default LandingPage;
