
import DesktopMenu from '../DesktopMenu/DesktopMenu';
import MobileMenu from '../MobileMenu/MobileMenu';
import logo from '../../assets/logoSubtitle02.png'
import './Header.scss';

function Header() {
  return (
    <header className='header'>
        <img src={logo} alt="logo da empresa" />
        <DesktopMenu />
        <MobileMenu />
    </header>
  )
}

export default Header;