import treining from '../assets/news/treining.png';
import negotiation from '../assets/news/negotiation.png';
import lab from '../assets/news/lab.png';
import equipment from '../assets/news/equipment.png';
import hamm from '../assets/news/Hamm.png';
import hamburgo from '../assets/news/Hamburgo.png';
import butantan from '../assets/news/butantan.png';
import proposta from '../assets/news/proposta.png';
import enertrafo from '../assets/news/Enertrafo.png';
import mineraltec from '../assets/news/Mineraltec.png';
import leafs from '../assets/news/Leafs.png';
import bp from '../assets/news/BP.png';
import oilTest from '../assets/news/OilTest.png';

export const news = [
    {
        image: oilTest,
        alt: 'Óleo sendo derramado',
        imgRights: <a href="https://unsplash.com/photos/person-holding-clear-drinking-glass-with-brown-liquid-Pmkq0yZ80-4?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash" target='_black' rel='noreferrer' >Imagem de Unsplash</a>,
        title: 'Implantação da NBR 13882/2021',
        content: 'OILFLEX finaliza a implantação de ensaio para determinação de PCBs em Óleos isolantes na TRAEL – MT. Dois equipamentos operacionalizados para realização dos ensaios 24h por dia.',
        date: '19/06/2024'
    },
    {
        image: bp,
        alt: 'Foto da empresa MINERALTEC',
        imgRights: <a href="https://unsplash.com/photos/person-holding-pencil-near-laptop-computer-5fNmWej4tAA?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash" target='_black' rel='noreferrer'>Imagem de Unsplash</a>,
        title: 'OILFLEX em proposta para projeto usina em Colombo - PR',
        content: 'OILFLEX participa de proposta de projeto para ECONOMIA CIRCULAR NO Reaproveitamento Energético de Resíduos Sólidos Urbanos e Agrícolas - Viabilidade e Tecnologia. O projeto visa a validação da viabilidade econômica, a criação de plano de aceleração, disseminação e plano de internacionalização do processo para produção de combustíveis renováveis em Colombo, Paraná.',
        date: '11/06/2024'
    },
    {
        image: leafs,
        alt: 'Foto da empresa MINERALTEC',
        imgRights: <a href="https://unsplash.com/photos/green-leaf-plant-qhB9ppZnNA8?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash" target='_black' rel='noreferrer'>Imagem de Unsplash</a>,
        title: 'OILFLEX participa de proposta para projeto FAPESC',
        content: 'OILFLEX se cadastra no projeto NOVO MODELO DE ECONOMIA CIRCULAR – COMBUSTÍVEIS VERDES, organizado pela FAPESC, que visa estabelecer um marco na geração de energia renovável na Serra Catarinense, utilizando a tecnologia para transformar biomassa residual em combustíveis renováveis de alta qualidade.',
        date: '11/06/2024'
    },
    {
        image: mineraltec,
        alt: 'Foto da empresa MINERALTEC',
        imgRights: <a href="https://unsplash.com/photos/yellow-and-white-abstract-painting-ReiMJUT19uI?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash" target='_blank' rel='noreferrer'>Imagem de Unsplash</a>,
        title: 'Visita empresarial à Mineraltec',
        content: 'OILFLEX visita a MINERALTEC em Pato Branco - PR, Empresa produtora de óleo vegetal para o setor elétrico.',
        date: '29/04/2024'
    },
    {
        image: enertrafo,
        alt: 'Foto da empresa Enertrafo',
        imgRights: <a href="https://br.freepik.com/fotos-gratis/homem-um-tecnico-eletrico-que-trabalha-em-um-quadro-de-distribuicao-com-fusiveis-usa-um-tablet_33650882.htm#fromView=search&page=1&position=21&uuid=6f576b8d-775d-466d-b8c6-590930b9a2ef" target='_blank' rel='noreferrer'>Imagem de Freepik</a>,
        title: 'Instalação de ensaio na empresa Enertrafo',
        content: 'OILFLEX apoia a instalação do ensaio de PCB pela NBR 13882 na ENERTRAFO. Mais uma empresa habilitada para este ensaio no setor elétrico.',
        date: '29/04/2024'
    },
    {
        image: proposta,
        alt: 'Foto de caminhões e equipamentos trabalhando na terra',
        imgRights: null,
        title: 'Proposta de captação de recursos',
        content: 'OILFLEX prepara proposta de captação de recurso no BNDES e na FINEP para ADL Mining.',
        date: '08/04/2024'
    },
    {
        image: butantan,
        alt: 'Foto da fachada do Instituto Butantan',
        imgRights: null,
        title: 'Visita ao Instituto Butantan',
        content: 'OILFLEX visita Instituto Butantan para viabilização de projetos de geração de biogás com resíduos de produção de vacinas.',
        date: '08/04/2024'
    },
    {
        image: hamburgo,
        alt: 'Foto da visita à Universidade de Hamburgo',
        imgRights: <a href="https://unsplash.com/photos/books-on-brown-wooden-shelves-I1v-tKAJzzc?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash" target='_blank' rel="noreferrer">Imagem de Unsplash</a>,
        title: 'Visita à Universidade de Hamburgo',
        content: 'OILFLEX visita o Instituto de Sistemas Computacionais em Biologia da Universidade de Hamburgo para cooperação na área de análise de dados da produção de Hidrogênio Renovável.',
        date: '27/03/2024'
    },
    {
        image: hamm,
        alt: 'Foto da visita à Hamm Lipstadt',
        imgRights: <a href="https://br.freepik.com/fotos-gratis/salao-da-biblioteca-limpo-e-vazio_21138957.htm#fromView=search&page=3&position=15&uuid=b26ef1f9-e821-4cc0-9bdc-2dd6b0515e3a" target='_blank' rel="noreferrer">Imagem de Freepik</a>,
        title: 'Visita à Hamm Lipstadt',
        content: 'OILFLEX visita a Universidade de Hamm Lipstadt para cooperação no desenvolvimento de tecnologia para certificação de Hidrogênio Renovável.',
        date: '27/03/2024'
    },
    {
        image: equipment,
        alt: 'foto de equipamentos industriais',
        imgRights: null,
        title: 'Novos equipamentos',
        content: 'Oilflex atende WEG: Conhecendo os novos equipamentos da fábrica em Blumenau. Parabéns pelas aquisições.',
        date: '05/02/2024'
    },
    {
        image: treining,
        alt: 'foto de treinamento profissional',
        imgRights: <a href="https://br.freepik.com/fotos-gratis/colegas-de-tiro-medio-aprendendo_23404617.htm#query=treinamento&position=5&from_view=search&track=sph&uuid=11802ba6-2bd1-4f15-8644-7f21ec66cce0" target='_blank' rel='noreferrer'>Imagem de Freepik</a>,
        title: 'Treinamento para gestão de laboratório',
        content: 'OILFLEX inicia preparação de treinamento para gestão de laboratórios para análise de óleos isolantes do setor elétrico.',
        date: '15/01/2024'
    },
    {
        image: negotiation,
        alt: 'foto de reunião de negociação',
        imgRights: <a href="https://br.freepik.com/fotos-gratis/pessoas-negocio-em-um-reuniao_2770453.htm#query=professional%20meeting&position=0&from_view=search&track=ais&uuid=7cfea9f4-f4b0-4c60-8bab-0a0c46d1ea10" target='_blank' rel='noreferrer'>Imagem de rawpixel.com</a>,
        title: 'Projetos de captação de recursos',
        content: 'OILFLEX inicia negociação com a ADL Mining para elaboração de projetos de captação de recursos.',
        date: '05/01/2024'
    },
    {
        image: lab,
        alt: 'foto de dois profissionais em um laboratório',
        imgRights: <a href="https://br.freepik.com/fotos-gratis/pesquisadores-do-laboratorio-de-biotecnologia-com-tablet-e-prancheta_11374990.htm#query=lab&position=10&from_view=search&track=sph&uuid=6d9dd0c6-a63d-459d-b93e-73522ef525f6" target='_blank' rel='noreferrer'>Imagem de Freepik</a>,
        title: 'Construção de laboratório',
        content: 'OILFEX inicia projeto de construção do laboratório de análise de óleos da ENERTRAFO.',
        date: '02/01/2024'
    }
];
