import { useRef, useState } from 'react';
import Header from '../../layout/Header/Header';
import Footer from '../../layout/Footer/Footer';
import emailjs from '@emailjs/browser';
import './Contact.scss';


function Contact() {
  const [values, setValues] = useState({});
  const form = useRef();

  function handleChange({ target: { name, value } }){
      setValues(({ ...values, [name]: value }))
  }

  function sendEmail(event){
    event.preventDefault();

    emailjs.sendForm('service_p7jgc52', 'template_lspqq5s', form.current , 'IJ5WKJ4O3z_S7fQrw')
        .then((result) => {
            alert("Mensagem enviada com suceeso, entraremos em contato");
        }, (error) => {
            console.log(error.text);
        });
        event.target.reset();
  }

  return (
    <>
      <Header />
      <form className='contact-form' ref={form} onSubmit={sendEmail} >
        <h2>Entre em contato</h2>
        <input type="text" placeholder='Nome'  name='name' onChange={handleChange} required/>
        <input type="email" placeholder='Email'  name='email' onChange={handleChange} required/>
        <input type="text" placeholder='Telefone'  name='phone' onChange={handleChange} required/>
        <input type="text" placeholder='Assunto'  name='subject' onChange={handleChange} required/>
        <textarea placeholder='Digite sua mensagem aqui'  name='message' onChange={handleChange} required/>
        <button type='submit'>Enviar mensagem</button>
      </form>
      <Footer />
    </>
  )
}

export default Contact;