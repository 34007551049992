import { useState } from 'react';
import { Link } from 'react-router-dom';
import './MobileMenu.scss';

function MobileMenu() {
    const [isOpened, setIsOpened] = useState(false);

    function handleClick() {
        setIsOpened(prevState => !prevState);
    }

    return (
        <>
            <button className='burger-btn' onClick={handleClick}> 
                <hr className={ isOpened ? 'first line X' : 'first line' }/>
                <hr className={ isOpened ? 'second line X' : 'second line' }/>
                <hr className={ isOpened ? 'last line X' : 'last line' }/>
            </button>
            <nav 
                className={isOpened ? 'side-bar open' : 'side-bar closed'}
                onClick={() => setIsOpened(false)}
            >
                <Link to='/' className='side-bar-link' >INÍCIO </Link>
                <Link to='/contatos' className='side-bar-link' >CONTATO</Link>
            </nav>
        </>
    )
}

export default MobileMenu;