import './NewsCard.scss';

function NewsCard({ image, alt, imgRights, title, content, date }) {
    return (
        <div className="news-card">
            <div className='image-section'>
                <img src={ image } alt={ alt }/>
                { imgRights }
            </div>

            <div className="text-section">
                <h3>{ title }</h3>
                <p>{ content }</p>

                <div className='info-section'>
                    <span>{ date }</span>
                    <span className='label'>Novidade</span>
                </div>
            </div>
        </div>
    )
}

export default NewsCard;
