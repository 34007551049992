import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './Card.scss';

function Card({ icon, title, text }) {
    useEffect(() => {
        Aos.init({
            offset: 200,
            duration: 2000,
            easing: 'ease-in-sine',
            delay: 400,
        })
    }, [])
    

    return (
        <div className='card' data-aos='fade-up'>
            {icon}
            <h3>{title}</h3>
            <p>{text}</p>
        </div>
    )
}

export default Card;