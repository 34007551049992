export const moreAbout = <p>
    <strong>A empresa atua em:</strong>
    <br /> <br />
    • Consultoria em Gestão Empresarial de Laboratórios;<br />
    • Tratamento de Dados Físico-Químcos;<br />
    • Treinamento Profissional;<br />
    • Educação Tecnológica e Profissional.<br />
    <br />

    <strong>Na área de consultoria atuamos com:</strong>
    <br /> <br />
    • Apoio na implementação de ensaios de laboratórios para realização de análises físico-químicas e especiais em óleos isolantes e lubrificantes industriais, além de análises de gases dissolvidos em óleos isolantes;<br />
    • Suporte na interpretação e emissão de diagnóstico sobre as condições dos óleos e/ou equipamentos, em função dos resultados obtidos nas análises, com recomendação de ações de manutenção preditiva ou corretiva;<br />
    • Elaboração de sistema estatístico para acompanhamento dos resultados de análises e de dados históricos, fornecendo ao cliente a situação das condições do óleo e/ou equipamento, com as tendências de comportamento futuro;
    <br />
    <br />

    <strong>Na área de serviços fornecemos as seguintes soluções:</strong>
    <br /> <br />
    • Regeneração de óleo mineral isolante em planta fixa;<br />
    • Regeneração de óleo mineral isolante com transformadores energizados, na instalação do cliente;<br />
    • Secagem de óleo mineral isolante e desidratação de parte ativa de transformadores, com utilização de filtros desidratadores;<br />
    • Descontaminação de óleos minerais isolantes que apresentem enxofre corrosivo, pela retirada de dibenzildissulfeto (DBDS), podendo o óleo retornar ao uso;<br />
    • Descontaminação de óleos minerais isolantes que possuam bifenilas policloradas – PCB (ascarel), podendo o óleo retornar ao uso, baseando-se na legislação vigente;<br />
    • Desenvolvimento de processos de regeneração de óleos minerais isolantes, visando atender aos projetos de P&D, ou necessidades especificas do cliente;<br />
    • Desenvolvimento e implantação de ensaios químicos especiais;<br />
    • Desenvolvimento de novas metodologias de ensaios físico-químicos;<br />
    • Elaboração de cursos nas áreas de materiais e química;<br />
    • Apoio técnico criação de ambientes e na execução de serviços, projetos e programas de pesquisa;<br />
    • Avaliações periódicas da qualidade e confiabilidade dos resultados de análises físico-químicas e de gases dissolvidos realizadas no óleo isolante;<br />
    • Manutenção de laboratórios físico-químicos atualizados em relação a normas e procedimentos vigentes no Brasil;<br />
    • Treinamento técnico, visando aprimorar os conhecimentos, para emissão de laudos complexos de equipamentos em uso, principalmente quanto a resultados de análises de gases dissolvidos em óleo isolante;<br />
    • Suporte para participação efetiva nas comparações laboratoriais formais;<br />
    • Especificação técnica de equipamentos para a realização e implantação de novos ensaios;<br />
    • Preparação de Procedimentos de Ensaio e Análise (PEA), para ensaios já implantados e novos;<br />
    • Implantação de laboratório para análises físico-químicas e de gases dissolvidos no óleo isolante;<br />
    • Completo suporte para implantação da NBR 13882:2021 em laboratório de ensaios físico-químicos;<br />
    • Completo suporte prévio para implantação da NBR 17025:2017 em laboratório de ensaios físico-químicos e de gases dissolvidos no óleo isolante, para o setor elétrico.<br />
    <br />

    <strong>Na área de Elaboração e Execução de Projetos fornecemos os seguintes suportes:</strong>
    <br /><br />
    • Suporte para elaboração e execução de projetos de P&D para atendimento aos requisitos do PEQUI ANEEL;<br />
    • Projetos para desenvolvimento de sistemas de tratamento e regeneração de líquidos isolantes.<br />
</p>
